$c-primary: #3a5c52;
$c-secandary: #3a5c52;
$dots: false;
$body-color-overrided: #000000;
$loading-bg-overrided: white;
$progress-bar-overided: #5bae51;
$loading-text-color-overrided: #000000;
$flow-v2-bg-header-overrided: #000000;
$progress-bar-incomplete-overided: #000;
$short-loader-dot1: #258752;
$short-loader-dot2: #19e480;
$short-loader-dot3: #258752;
$short-loader-dot4: #19e480;
$base-font-family: Arial, Helvetica, sans-serif;
$font-family-heading: 'Century Gothic', sans-serif;


            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
$primary: var(--primary);
.wrapper {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: stretch;
  text-align: left;

  .description {
    text-align: left;
    margin-bottom: 1.5rem; //24
  }

  .container {
    max-width: calc(100vw - 3rem);
    .userDetailRow {
      position: relative;
      & > label {
        &:first-child {
          position: absolute;
          left: 0;
          top: 0;
          width: 35%;
          font-weight: bold;
          font-size: 1rem; //16
          line-height: 1.2;
          z-index: 1;
          height: 3.75rem; //60
          display: flex;
          align-items: center;
          word-break: break-word;
        }
      }
      .address-label-span {
        height: 5rem !important; //80
      }
    }
    .check-confirm {
      margin-top: 2.5rem;
      margin-bottom: 1rem;
    }
  }

  .container-one-doc {
    @extend .container;
    border-top: 1px solid rgb(204, 204, 204);
  }

  .error {
    color: red;
    font-size: 0.75rem; //12
  }

  .input {
    .halfWith {
      max-width: 50%;
      margin-right: 1rem !important;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
