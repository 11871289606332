$c-primary: #3a5c52;
$c-secandary: #3a5c52;
$dots: false;
$body-color-overrided: #000000;
$loading-bg-overrided: white;
$progress-bar-overided: #5bae51;
$loading-text-color-overrided: #000000;
$flow-v2-bg-header-overrided: #000000;
$progress-bar-incomplete-overided: #000;
$short-loader-dot1: #258752;
$short-loader-dot2: #19e480;
$short-loader-dot3: #258752;
$short-loader-dot4: #19e480;
$base-font-family: Arial, Helvetica, sans-serif;
$font-family-heading: 'Century Gothic', sans-serif;


            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.progress {
  height: 1.25rem; //20
  background: #f5f5f5;
  position: relative;
  margin-top: 1rem; //16
  border-radius: 0.3125rem; //5

  .bar {
    border-radius: 0.3125rem; //5
    background-color: black;
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 100%;
    transition: width 0.6s ease;
  }
}
