$c-primary: #3a5c52;
$c-secandary: #3a5c52;
$dots: false;
$body-color-overrided: #000000;
$loading-bg-overrided: white;
$progress-bar-overided: #5bae51;
$loading-text-color-overrided: #000000;
$flow-v2-bg-header-overrided: #000000;
$progress-bar-incomplete-overided: #000;
$short-loader-dot1: #258752;
$short-loader-dot2: #19e480;
$short-loader-dot3: #258752;
$short-loader-dot4: #19e480;
$base-font-family: Arial, Helvetica, sans-serif;
$font-family-heading: 'Century Gothic', sans-serif;


            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.formInputRow {
  position: relative;

  & > span {
    &:first-child {
      position: absolute;
      left: 0;
      top: 0;
      width: 35%;
      font-weight: bold;
      font-size: 1rem; //16
      line-height: 1.2;
      z-index: 1;
      height: 3.75rem; //60
      display: flex;
      align-items: center;
    }
  }
}

.control {
  background-color: white;
  border-bottom: 1px solid rgba(25, 25, 25, 0.32);
  border-radius: 0;
  padding-left: 35%;
}
