$c-primary: #3a5c52;
$c-secandary: #3a5c52;
$dots: false;
$body-color-overrided: #000000;
$loading-bg-overrided: white;
$progress-bar-overided: #5bae51;
$loading-text-color-overrided: #000000;
$flow-v2-bg-header-overrided: #000000;
$progress-bar-incomplete-overided: #000;
$short-loader-dot1: #258752;
$short-loader-dot2: #19e480;
$short-loader-dot3: #258752;
$short-loader-dot4: #19e480;
$base-font-family: Arial, Helvetica, sans-serif;
$font-family-heading: 'Century Gothic', sans-serif;


            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  right: 0;
  overflow: auto;
  padding: 90px 25px 30px;

  h3 {
    margin-top: 25px;
    max-width: 180px;
    text-align: center;
  }

  button {
    max-width: 265px;
  }

  .image {
    display: -webkit-box;
    flex: 1;
    width: 100%;
    min-height: 120px;
    max-height: 280px;
    max-width: 320px;
    margin-top: 20px;
    overflow: initial !important;
    overflow-x: hidden !important;

    > div {
      height: 100%;
      overflow: initial !important;
      display: block;
      position: absolute !important;
    }

    img {
      display: inline-block;
      margin: auto;
      height: 100%;
    }
  }

  .dots {
    margin: 30px 0;
    padding: 0;

    li {
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: $c-grey;
      margin: 0 5px;

      &.active {
        background: var(--primary);
      }
    }
  }
}

@media (max-height: 635px) {
  .wrapper h3 {
    margin-top: 0;
  }
}

@media (max-height: 620px) {
  .wrapper .dots {
    margin: 10px 0;
  }
}

@media (max-height: 550px) {
  .wrapper .image {
    max-width: 260px;
    margin-top: 5px;
  }

  .wrapper .dots {
    margin: 15px 0;
  }
}

@media (max-height: 500px) {
  .wrapper .image {
    max-width: 230px;
  }
}
