$c-primary: #3a5c52;
$c-secandary: #3a5c52;
$dots: false;
$body-color-overrided: #000000;
$loading-bg-overrided: white;
$progress-bar-overided: #5bae51;
$loading-text-color-overrided: #000000;
$flow-v2-bg-header-overrided: #000000;
$progress-bar-incomplete-overided: #000;
$short-loader-dot1: #258752;
$short-loader-dot2: #19e480;
$short-loader-dot3: #258752;
$short-loader-dot4: #19e480;
$base-font-family: Arial, Helvetica, sans-serif;
$font-family-heading: 'Century Gothic', sans-serif;


            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.content {
  word-break: break-word;

  a {
    font-style: italic;
  }
}
